import React from 'react';

interface EventCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  minScore: string;
  maxScore: string;
}

export const EventCard: React.FC<EventCardProps> = ({
  title,
  description,
  icon,
  minScore,
  maxScore,
}) => {
  return (
    <div className="bg-surface rounded-lg shadow-lg p-6 transition-transform hover:scale-105 border border-gray-700">
      <div className="flex items-center mb-4">
        <div className="p-2 bg-background rounded-lg">
          {icon}
        </div>
        <h3 className="ml-3 text-xl font-semibold text-secondary">{title}</h3>
      </div>
      <p className="text-gray-300 mb-4">{description}</p>
      <div className="flex justify-between text-sm text-gray-400">
        <span>Min: {minScore}</span>
        <span>Max: {maxScore}</span>
      </div>
    </div>
  );
};