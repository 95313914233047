import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Privacy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | ACFT Calculator</title>
        <meta name="description" content="Read our privacy policy to understand how we collect, use, and protect your data when using the ACFT Calculator." />
      </Helmet>
      <div className="bg-white py-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-primary mb-8">Privacy Policy</h1>
            <div className="prose prose-lg">
              <p className="text-gray-600 mb-6">
                Last updated: {new Date().toLocaleDateString()}
              </p>
              <h2 className="text-2xl font-bold text-primary mt-8 mb-4">Information We Collect</h2>
              <p className="text-gray-600 mb-6">
                We collect information that you provide directly to us when using the ACFT Calculator, including your fitness test scores and basic profile information.
              </p>
              <h2 className="text-2xl font-bold text-primary mt-8 mb-4">How We Use Your Information</h2>
              <p className="text-gray-600 mb-6">
                We use the information we collect to provide and improve the ACFT Calculator service, generate score reports, and enhance your user experience.
              </p>
              <h2 className="text-2xl font-bold text-primary mt-8 mb-4">Data Security</h2>
              <p className="text-gray-600 mb-6">
                We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};