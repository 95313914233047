import React from 'react';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';

const faqs = [
  {
    question: "What is the ACFT?",
    answer: "The Army Combat Fitness Test (ACFT) is the U.S. Army's official physical fitness test consisting of six events: Maximum Deadlift, Standing Power Throw, Hand-Release Push-ups, Sprint-Drag-Carry, Plank, and 2-Mile Run."
  },
  {
    question: "How is the ACFT scored?",
    answer: "Each event is scored on a scale of 0-100 points. The final score is an average of all six events, with a minimum passing score of 60 points required for each event."
  },
  {
    question: "How often do soldiers take the ACFT?",
    answer: "Active duty soldiers are required to take the ACFT at least twice per year, with a minimum of 4 months between tests."
  },
  {
    question: "What happens if I fail the ACFT?",
    answer: "Soldiers who fail the ACFT will be provided with training guidance and must retake the test. Multiple failures may result in administrative action or separation from service."
  }
];

export const FAQ: React.FC = () => {
  return (
    <div className="bg-background py-16" id="faq">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-secondary text-center mb-12">
            Frequently Asked Questions
          </h2>
          <dl className="space-y-6">
            {faqs.map((faq, index) => (
              <Disclosure as="div" key={index} className="bg-surface rounded-lg shadow border border-gray-700">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-4 text-left text-lg font-medium text-secondary hover:bg-background">
                      <span>{faq.question}</span>
                      <FaChevronDown
                        className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-secondary`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-4 pt-2 text-gray-300">
                      {faq.answer}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};