import React from 'react';
import { FaDumbbell, FaRunning, FaHeartbeat, FaAppleAlt } from 'react-icons/fa';

const tips = [
  {
    title: "Progressive Training",
    description: "Start with lighter weights and gradually increase. Focus on proper form before adding weight.",
    icon: <FaDumbbell className="h-6 w-6 text-secondary" />
  },
  {
    title: "Cardio Endurance",
    description: "Include both sprint intervals and long-distance running in your training program.",
    icon: <FaRunning className="h-6 w-6 text-secondary" />
  },
  {
    title: "Recovery",
    description: "Allow adequate rest between training sessions. Focus on sleep and proper nutrition.",
    icon: <FaHeartbeat className="h-6 w-6 text-secondary" />
  },
  {
    title: "Nutrition",
    description: "Maintain a balanced diet rich in protein, complex carbs, and healthy fats.",
    icon: <FaAppleAlt className="h-6 w-6 text-secondary" />
  }
];

export const TrainingTips: React.FC = () => {
  return (
    <div className="bg-background py-16" id="training">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-secondary text-center mb-12">
          Training Tips & Resources
        </h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {tips.map((tip, index) => (
            <div key={index} className="relative p-6 bg-surface rounded-xl hover:shadow-lg transition-shadow border border-gray-700">
              <div className="absolute top-6 left-6">{tip.icon}</div>
              <div className="pt-12">
                <h3 className="text-lg font-semibold text-secondary mb-2">{tip.title}</h3>
                <p className="text-gray-300">{tip.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};