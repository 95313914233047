import React from 'react';

export const Hero: React.FC = () => {
  return (
    <div className="relative bg-primary py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-secondary sm:text-6xl">
            Army Combat Fitness Test
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            The ACFT is the U.S. Army's official physical fitness test. Master the six events and achieve your peak performance with our comprehensive resources and calculator.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a href="#calculator" className="rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-primary shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
              Try Calculator
            </a>
            <a href="#events" className="text-sm font-semibold leading-6 text-secondary">
              Learn More <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};