import React from 'react';
import { Helmet } from 'react-helmet-async';

export const About: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>About | ACFT Calculator</title>
        <meta name="description" content="Learn about the ACFT Calculator tool and its mission to help soldiers prepare for the Army Combat Fitness Test." />
      </Helmet>
      <div className="bg-white py-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-primary mb-8">About ACFT Calculator</h1>
            <div className="prose prose-lg">
              <p className="text-gray-600 mb-6">
                The ACFT Calculator is a comprehensive tool designed to help service members prepare for and track their performance on the Army Combat Fitness Test (ACFT).
              </p>
              <p className="text-gray-600 mb-6">
                Our mission is to provide accurate scoring calculations and valuable training resources to support soldiers in meeting the Army's physical fitness standards.
              </p>
              <h2 className="text-2xl font-bold text-primary mt-8 mb-4">Our Features</h2>
              <ul className="list-disc pl-6 text-gray-600 mb-6">
                <li>Accurate ACFT score calculations</li>
                <li>Detailed event breakdowns</li>
                <li>Training tips and resources</li>
                <li>Downloadable score reports</li>
                <li>Mobile-friendly interface</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};