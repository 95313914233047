import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Hero } from '../components/Hero';
import { Calculator } from '../components/Calculator';
import { EventCard } from '../components/EventCard';
import { TrainingTips } from '../components/TrainingTips';
import { FAQ } from '../components/FAQ';
import { 
  FaDumbbell, 
  FaRunning, 
  FaStopwatch, 
  FaWeight,
  FaHandPaper,
  FaClock
} from 'react-icons/fa';

export const Home = () => {
  const events = [
    {
      title: "Maximum Deadlift",
      description: "Tests lower body and core strength through a three-repetition maximum deadlift.",
      icon: <FaWeight className="h-6 w-6 text-secondary" />,
      minScore: "140 lbs",
      maxScore: "340 lbs"
    },
    {
      title: "Standing Power Throw",
      description: "Measures explosive power through backward medicine ball throw.",
      icon: <FaDumbbell className="h-6 w-6 text-secondary" />,
      minScore: "3.5m",
      maxScore: "12.5m"
    },
    {
      title: "Hand-Release Push-ups",
      description: "Tests upper body endurance through two-minute push-up event.",
      icon: <FaHandPaper className="h-6 w-6 text-secondary" />,
      minScore: "1 rep",
      maxScore: "60 reps"
    },
    {
      title: "Sprint-Drag-Carry",
      description: "Assesses anaerobic capacity through a series of sprints.",
      icon: <FaRunning className="h-6 w-6 text-secondary" />,
      minScore: "3:03",
      maxScore: "1:33"
    },
    {
      title: "Plank",
      description: "Tests core endurance through timed plank hold.",
      icon: <FaClock className="h-6 w-6 text-secondary" />,
      minScore: "0:45",
      maxScore: "3:20"
    },
    {
      title: "2-Mile Run",
      description: "Measures aerobic endurance through timed run.",
      icon: <FaStopwatch className="h-6 w-6 text-secondary" />,
      minScore: "18:00",
      maxScore: "13:30"
    }
  ];

  return (
    <>
      <Helmet>
        <title>ACFT Calculator | Army Combat Fitness Test Score Chart</title>
        <meta name="description" content="Calculate your Army Combat Fitness Test score with our official ACFT calculator. Get instant results and downloadable score reports." />
      </Helmet>
      <Hero />
      <Calculator />
      <div className="py-16 bg-background" id="events">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-secondary text-center mb-12">
            ACFT Events
          </h2>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {events.map((event, index) => (
              <EventCard key={index} {...event} />
            ))}
          </div>
        </div>
      </div>
      <TrainingTips />
      <FAQ />
    </>
  );
};