import React from 'react';
import { Helmet } from 'react-helmet-async';

export const DMCA: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>DMCA Policy | ACFT Calculator</title>
        <meta name="description" content="Read our DMCA policy regarding copyright protection and content usage on the ACFT Calculator website." />
      </Helmet>
      <div className="bg-white py-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-primary mb-8">DMCA Policy</h1>
            <div className="prose prose-lg">
              <p className="text-gray-600 mb-6">
                Last updated: {new Date().toLocaleDateString()}
              </p>
              <h2 className="text-2xl font-bold text-primary mt-8 mb-4">Copyright Notice</h2>
              <p className="text-gray-600 mb-6">
                We respect the intellectual property rights of others and expect our users to do the same. In accordance with the Digital Millennium Copyright Act (DMCA), we will respond to notices of alleged copyright infringement.
              </p>
              <h2 className="text-2xl font-bold text-primary mt-8 mb-4">Filing a DMCA Notice</h2>
              <p className="text-gray-600 mb-6">
                To file a copyright infringement notification with us, you will need to send a written communication that includes substantially the following information:
              </p>
              <ul className="list-disc pl-6 text-gray-600 mb-6">
                <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                <li>Identification of the copyrighted work claimed to have been infringed.</li>
                <li>Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and email address.</li>
                <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};