import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#ffffff'
  },
  header: {
    marginBottom: 20,
    borderBottom: '1pt solid black',
    paddingBottom: 10,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 5,
    color: '#444444',
  },
  date: {
    fontSize: 12,
    textAlign: 'center',
    color: '#666666',
  },
  scoreSection: {
    marginTop: 20,
  },
  eventRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    padding: 5,
  },
  eventLabel: {
    fontSize: 12,
    flex: 2,
  },
  eventScore: {
    fontSize: 12,
    flex: 1,
  },
  totalScore: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#f3f4f6',
    borderRadius: 5,
  },
  totalScoreText: {
    fontSize: 16,
    textAlign: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    fontSize: 10,
    color: '#666666',
  },
});

interface ScoreReportProps {
  scores: {
    mdl: string;
    spt: string;
    hrp: string;
    sdc: string;
    plk: string;
    tmr: string;
  };
  totalScore: number;
  gender: 'male' | 'female';
  ageGroup: string;
}

export const ScoreReport: React.FC<ScoreReportProps> = ({ 
  scores, 
  totalScore, 
  gender, 
  ageGroup 
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>ACFT Score Report</Text>
        <Text style={styles.subtitle}>
          {gender.charAt(0).toUpperCase() + gender.slice(1)} | Age Group: {ageGroup}
        </Text>
        <Text style={styles.date}>
          {format(new Date(), 'MMMM dd, yyyy')}
        </Text>
      </View>

      <View style={styles.scoreSection}>
        <View style={styles.eventRow}>
          <Text style={styles.eventLabel}>Maximum Deadlift (MDL)</Text>
          <Text style={styles.eventScore}>{scores.mdl} lbs</Text>
        </View>
        <View style={styles.eventRow}>
          <Text style={styles.eventLabel}>Standing Power Throw (SPT)</Text>
          <Text style={styles.eventScore}>{scores.spt} m</Text>
        </View>
        <View style={styles.eventRow}>
          <Text style={styles.eventLabel}>Hand-Release Push-ups (HRP)</Text>
          <Text style={styles.eventScore}>{scores.hrp} reps</Text>
        </View>
        <View style={styles.eventRow}>
          <Text style={styles.eventLabel}>Sprint-Drag-Carry (SDC)</Text>
          <Text style={styles.eventScore}>{scores.sdc} min</Text>
        </View>
        <View style={styles.eventRow}>
          <Text style={styles.eventLabel}>Plank (PLK)</Text>
          <Text style={styles.eventScore}>{scores.plk} min</Text>
        </View>
        <View style={styles.eventRow}>
          <Text style={styles.eventLabel}>2-Mile Run (2MR)</Text>
          <Text style={styles.eventScore}>{scores.tmr} min</Text>
        </View>
      </View>

      <View style={styles.totalScore}>
        <Text style={styles.totalScoreText}>
          Total Score: {totalScore}
        </Text>
      </View>

      <Text style={styles.footer}>
        Generated at ACFTCALCULATORS.ORG
      </Text>
    </Page>
  </Document>
);