import React from 'react';

interface ScoreDisplayProps {
  score: number;
}

export const ScoreDisplay: React.FC<ScoreDisplayProps> = ({ score }) => {
  const getScoreColor = (score: number) => {
    return score >= 60 ? 'text-green-400' : 'text-red-400';
  };

  return (
    <div className="text-center p-6 bg-background rounded-lg shadow-lg border border-gray-700">
      <h2 className="text-xl font-semibold mb-2 text-secondary">Total Score</h2>
      <p className={`text-5xl font-bold ${getScoreColor(score)}`}>
        {score}
      </p>
      <p className={`text-sm mt-2 ${getScoreColor(score)}`}>
        {score >= 60 ? 'Passing' : 'Failing'}
      </p>
    </div>
  );
};