// Scoring tables based on official ACFT standards
interface ScoreRange {
  male: { [key: string]: number };
  female: { [key: string]: number };
}

const MDLScores: ScoreRange = {
  male: {
    340: 100, 330: 97, 320: 94, 310: 92, 300: 90,
    290: 87, 280: 85, 270: 82, 260: 80, 250: 77,
    240: 75, 230: 72, 220: 70, 210: 68, 200: 65,
    190: 62, 180: 60, 170: 57, 160: 55, 150: 50,
    140: 45
  },
  female: {
    340: 100, 330: 98, 320: 96, 310: 94, 300: 92,
    290: 90, 280: 88, 270: 86, 260: 84, 250: 82,
    240: 80, 230: 77, 220: 74, 210: 71, 200: 68,
    190: 65, 180: 62, 170: 60, 160: 57, 150: 54,
    140: 50
  }
};

const SPTScores: ScoreRange = {
  male: {
    12.5: 100, 12.2: 97, 11.9: 94, 11.6: 92, 11.3: 90,
    11.0: 87, 10.7: 85, 10.4: 82, 10.1: 80, 9.8: 77,
    9.5: 75, 9.2: 72, 8.9: 70, 8.6: 68, 8.3: 65,
    8.0: 62, 7.7: 60, 7.4: 57, 7.1: 55, 6.8: 52,
    6.5: 50, 4.5: 45
  },
  female: {
    12.5: 100, 12.0: 97, 11.5: 94, 11.0: 92, 10.5: 90,
    10.0: 87, 9.5: 85, 9.0: 82, 8.5: 80, 8.0: 77,
    7.5: 75, 7.0: 72, 6.5: 70, 6.0: 68, 5.5: 65,
    5.0: 62, 4.5: 60, 4.0: 57, 3.5: 55, 3.0: 52,
    2.5: 50
  }
};

const HRPScores: ScoreRange = {
  male: {
    60: 100, 57: 97, 54: 94, 51: 92, 48: 90,
    45: 87, 42: 85, 39: 82, 36: 80, 33: 77,
    30: 75, 27: 72, 24: 70, 21: 68, 18: 65,
    15: 62, 12: 60, 9: 57, 6: 55, 3: 52,
    1: 50
  },
  female: {
    60: 100, 56: 97, 52: 94, 48: 92, 44: 90,
    40: 87, 36: 85, 32: 82, 28: 80, 24: 77,
    20: 75, 16: 72, 12: 70, 10: 68, 8: 65,
    6: 62, 4: 60, 3: 57, 2: 55, 1: 52
  }
};

// Convert time string (MM:SS) to seconds
const timeToSeconds = (time: string): number => {
  if (!time || !time.includes(':')) return 0;
  const [minutes, seconds] = time.split(':').map(Number);
  if (isNaN(minutes) || isNaN(seconds)) return 0;
  return minutes * 60 + seconds;
};

// Convert seconds to time string (MM:SS)
const secondsToTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

// For SDC, lower time is better, so we need to invert the comparison
const getSDCScore = (timeInSeconds: number, table: { [key: string]: number }): number => {
  const thresholds = Object.keys(table).map(Number).sort((a, b) => a - b);
  for (const threshold of thresholds) {
    if (timeInSeconds <= threshold) {
      return table[threshold];
    }
  }
  return 0;
};

// For PLK, higher time is better
const getPLKScore = (timeInSeconds: number, table: { [key: string]: number }): number => {
  const thresholds = Object.keys(table).map(Number).sort((a, b) => b - a);
  for (const threshold of thresholds) {
    if (timeInSeconds >= threshold) {
      return table[threshold];
    }
  }
  return 0;
};

// For 2MR, lower time is better
const get2MRScore = (timeInSeconds: number, table: { [key: string]: number }): number => {
  const thresholds = Object.keys(table).map(Number).sort((a, b) => a - b);
  for (const threshold of thresholds) {
    if (timeInSeconds <= threshold) {
      return table[threshold];
    }
  }
  return 0;
};

const SDCScores: ScoreRange = {
  male: {
    93: 100, 98: 97, 103: 94, 108: 92, 113: 90,
    118: 87, 123: 85, 128: 82, 133: 80, 138: 77,
    143: 75, 148: 72, 153: 70, 158: 68, 163: 65,
    168: 62, 173: 60, 178: 57, 183: 55, 188: 52,
    193: 50
  },
  female: {
    93: 100, 100: 97, 107: 94, 114: 92, 121: 90,
    128: 87, 135: 85, 142: 82, 149: 80, 156: 77,
    163: 75, 170: 72, 177: 70, 184: 68, 191: 65,
    198: 62, 205: 60, 212: 57, 219: 55, 226: 52,
    233: 50
  }
};

const PLKScores: ScoreRange = {
  male: {
    200: 100, 190: 97, 180: 94, 170: 92, 160: 90,
    150: 87, 140: 85, 130: 82, 120: 80, 110: 77,
    100: 75, 90: 72, 80: 70, 70: 68, 60: 65,
    50: 62, 45: 60
  },
  female: {
    200: 100, 190: 97, 180: 94, 170: 92, 160: 90,
    150: 87, 140: 85, 130: 82, 120: 80, 110: 77,
    100: 75, 90: 72, 80: 70, 70: 68, 60: 65,
    50: 62, 45: 60
  }
};

const TMRScores: ScoreRange = {
  male: {
    810: 100, 840: 97, 870: 94, 900: 92, 930: 90,
    960: 87, 990: 85, 1020: 82, 1050: 80, 1080: 77,
    1110: 75, 1140: 72, 1170: 70, 1200: 68, 1230: 65,
    1260: 62, 1290: 60, 1320: 57, 1350: 55, 1380: 52,
    1410: 50
  },
  female: {
    810: 100, 850: 97, 890: 94, 930: 92, 970: 90,
    1010: 87, 1050: 85, 1090: 82, 1130: 80, 1170: 77,
    1210: 75, 1250: 72, 1290: 70, 1330: 68, 1370: 65,
    1410: 62, 1450: 60, 1490: 57, 1530: 55, 1570: 52,
    1610: 50
  }
};

const getScoreFromTable = (value: number, table: { [key: string]: number }): number => {
  const thresholds = Object.keys(table).map(Number).sort((a, b) => b - a);
  for (const threshold of thresholds) {
    if (value >= threshold) {
      return table[threshold];
    }
  }
  return 0;
};

export const calculateTotalScore = (
  gender: 'male' | 'female',
  mdl: string,
  spt: string,
  hrp: string,
  sdc: string,
  plk: string,
  tmr: string
): number => {
  const mdlScore = getScoreFromTable(Number(mdl), MDLScores[gender]);
  const sptScore = getScoreFromTable(Number(spt), SPTScores[gender]);
  const hrpScore = getScoreFromTable(Number(hrp), HRPScores[gender]);
  
  // Convert time strings to seconds and calculate scores
  const sdcSeconds = timeToSeconds(sdc);
  const plkSeconds = timeToSeconds(plk);
  const tmrSeconds = timeToSeconds(tmr);

  const sdcScore = getSDCScore(sdcSeconds, SDCScores[gender]);
  const plkScore = getPLKScore(plkSeconds, PLKScores[gender]);
  const tmrScore = get2MRScore(tmrSeconds, TMRScores[gender]);

  const scores = [mdlScore, sptScore, hrpScore, sdcScore, plkScore, tmrScore];
  const validScores = scores.filter(score => score > 0);
  
  // Only calculate average if we have valid scores
  return validScores.length > 0 
    ? Math.round(validScores.reduce((sum, score) => sum + score, 0) / validScores.length)
    : 0;
};