import React from 'react';
import { clsx } from 'clsx';

interface EventInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  unit: string;
  placeholder: string;
}

export const EventInput: React.FC<EventInputProps> = ({
  label,
  value,
  onChange,
  unit,
  placeholder,
}) => {
  const handleTimeInput = (input: string) => {
    if (unit === 'min:sec') {
      let cleaned = input.replace(/[^\d:]/g, '');
      
      if (cleaned.length < value.length) {
        onChange(cleaned);
        return;
      }

      cleaned = cleaned.replace(/:/g, '');
      
      if (cleaned.length <= 2) {
        onChange(cleaned);
      } else if (cleaned.length <= 4) {
        const seconds = cleaned.slice(-2);
        const minutes = cleaned.slice(0, -2);
        onChange(`${minutes}:${seconds}`);
      }
    } else {
      onChange(input);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-secondary mb-1">
        {label}
      </label>
      <div className="relative rounded-md shadow-sm">
        <input
          type={unit === 'min:sec' ? 'text' : 'number'}
          value={value}
          onChange={(e) => handleTimeInput(e.target.value)}
          placeholder={placeholder}
          className="block w-full rounded-md border-gray-600 bg-background text-secondary focus:border-secondary focus:ring-secondary sm:text-sm pr-12"
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-secondary sm:text-sm">{unit}</span>
        </div>
      </div>
    </div>
  );
};