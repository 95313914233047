import React, { useState } from 'react';
import { EventInput } from './EventInput';
import { ScoreDisplay } from './ScoreDisplay';
import { calculateTotalScore } from '../utils/scoreCalculator';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { ScoreReport } from './ScoreReport';
import { FaFilePdf, FaCalculator } from 'react-icons/fa';

const ageGroups = [
  '17-21', '22-26', '27-31', '32-36', '37-41',
  '42-46', '47-51', '52-56', '57-61', '62+'
];

export const Calculator: React.FC = () => {
  const [gender, setGender] = useState<'male' | 'female'>('male');
  const [ageGroup, setAgeGroup] = useState('17-21');
  const [scores, setScores] = useState({
    mdl: '',
    spt: '',
    hrp: '',
    sdc: '',
    plk: '',
    tmr: ''
  });

  const handleScoreChange = (event: string, value: string) => {
    setScores(prev => ({ ...prev, [event]: value }));
  };

  const totalScore = calculateTotalScore(
    gender,
    scores.mdl,
    scores.spt,
    scores.hrp,
    scores.sdc,
    scores.plk,
    scores.tmr
  );

  const isValidScore = !isNaN(totalScore) && totalScore > 0;

  const scoreReport = (
    <ScoreReport 
      scores={scores} 
      totalScore={totalScore} 
      gender={gender}
      ageGroup={ageGroup}
    />
  );

  return (
    <div className="py-16 bg-background" id="calculator">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <div className="bg-surface rounded-xl shadow-lg p-8">
          <div className="flex items-center justify-center gap-2 mb-6">
            <FaCalculator className="text-secondary text-2xl" />
            <h2 className="text-2xl font-bold text-secondary text-center">
              ACFT Score Calculator
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div>
              <label className="block text-sm font-medium text-secondary mb-2">
                Gender
              </label>
              <div className="flex gap-4">
                <button
                  onClick={() => setGender('male')}
                  className={`px-4 py-2 rounded-md ${
                    gender === 'male'
                      ? 'bg-secondary text-primary'
                      : 'bg-background text-secondary hover:bg-surface'
                  }`}
                >
                  Male
                </button>
                <button
                  onClick={() => setGender('female')}
                  className={`px-4 py-2 rounded-md ${
                    gender === 'female'
                      ? 'bg-secondary text-primary'
                      : 'bg-background text-secondary hover:bg-surface'
                  }`}
                >
                  Female
                </button>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-secondary mb-2">
                Age Group
              </label>
              <select
                value={ageGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-600 bg-background text-secondary focus:border-secondary focus:ring-secondary"
              >
                {ageGroups.map((age) => (
                  <option key={age} value={age}>
                    {age} years
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <EventInput
                label="Maximum Deadlift (MDL)"
                value={scores.mdl}
                onChange={(value) => handleScoreChange('mdl', value)}
                unit="lbs"
                placeholder="140-340"
              />
              <EventInput
                label="Standing Power Throw (SPT)"
                value={scores.spt}
                onChange={(value) => handleScoreChange('spt', value)}
                unit="m"
                placeholder="4.5-12.5"
              />
              <EventInput
                label="Hand-Release Push-ups (HRP)"
                value={scores.hrp}
                onChange={(value) => handleScoreChange('hrp', value)}
                unit="reps"
                placeholder="1-60"
              />
            </div>
            <div>
              <EventInput
                label="Sprint-Drag-Carry (SDC)"
                value={scores.sdc}
                onChange={(value) => handleScoreChange('sdc', value)}
                unit="min:sec"
                placeholder="1:33-3:03"
              />
              <EventInput
                label="Plank (PLK)"
                value={scores.plk}
                onChange={(value) => handleScoreChange('plk', value)}
                unit="min:sec"
                placeholder="0:45-3:20"
              />
              <EventInput
                label="2-Mile Run (2MR)"
                value={scores.tmr}
                onChange={(value) => handleScoreChange('tmr', value)}
                unit="min:sec"
                placeholder="13:30-21:00"
              />
            </div>
          </div>

          <div className="mt-8">
            <ScoreDisplay score={totalScore} />
          </div>

          {isValidScore && (
            <div className="mt-6 text-center">
              <BlobProvider document={scoreReport}>
                {({ url, loading }) => (
                  loading ? (
                    <span className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-primary bg-secondary opacity-50 cursor-not-allowed">
                      <FaFilePdf className="mr-2" />
                      Generating PDF...
                    </span>
                  ) : url ? (
                    <a
                      href={url}
                      download={`ACFT_Score_Report_${new Date().toISOString().split('T')[0]}.pdf`}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-primary bg-secondary hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                    >
                      <FaFilePdf className="mr-2" />
                      Download PDF Report
                    </a>
                  ) : null
                )}
              </BlobProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};