import React from 'react';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-primary">
      <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-secondary font-bold text-lg mb-4">ACFT Calculator</h3>
            <p className="text-gray-300 text-sm">
              Your trusted tool for Army Combat Fitness Test scoring and preparation.
            </p>
          </div>
          <div>
            <h3 className="text-secondary font-bold text-lg mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-300 hover:text-secondary text-sm">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-300 hover:text-secondary text-sm">
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-300 hover:text-secondary text-sm">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-secondary font-bold text-lg mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy" className="text-gray-300 hover:text-secondary text-sm">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/dmca" className="text-gray-300 hover:text-secondary text-sm">
                  DMCA
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-700 pt-8">
          <p className="text-center text-gray-300 text-sm">
            © {new Date().getFullYear()} ACFT Calculator. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};